.green-text {
  color: #3cad66;
}

.blue-text {
  color: #0080fe;
}

.pp-brown {
  background-color: #aa945b !important;
}
