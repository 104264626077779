body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.sidebar {
  position: fixed;
  top: 3rem;
  bottom: 0;
  left: 0;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  height: calc(100vh - 48px);
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #2470dc;
}

.sidebar .nav-link:hover,
.sidebar .nav-link.active {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
}

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
}

.form-group {
  margin-bottom: 2%;
}

/* a.nav-link.d-flex.align-items-center.gap-2.active */

ul#sideNavigation > li > a.active {
  background-color: lightgrey;
}

@media (max-width: 576px) {
  main {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  #storefrontRow {
    justify-content: center;
  }
}
