.my-popover {
  min-width: 30% !important;
}

.trash:hover {
  cursor: pointer;
}

@media (max-width: 576px) {
  .my-popover {
    min-width: 100% !important;
  }
}
